import './Header.scss'
import {Container, Row} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.css';
import logo from '../images/varstack2.png'

function Header() {
  return (
    <Container fluid className='header_container'>
      <Row className='main_row'>
      <img src={logo} alt="" />

      </Row>
      
    </Container>
  )
}

export default Header