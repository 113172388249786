import React from "react";
import "./pages.css";
function Contact() {
  return (
    <div className="about_body">
      <h1>
        Contact Us! <br /> <br />
      </h1>
      <p>
        Dr. Ece (Gamsiz) Uzun <br />
        Director of Clinical Bioinformatics at Lifespan Academic Medical Center,
        Assistant Professor at the Department of Pathology and Laboratory
        Medicine at Brown University.
        <br />
        <a href="mailto: Dilber_Gamsiz@brown.edu"> Dilber_Gamsiz@brown.edu </a>
      </p>
      <p>
        Dr. Alper Uzun <br/>
        Associate Professor of Pathology and Laboratory Medicine at Brown University <br />
        <a href="mailto: Alper_Uzun@brown.edu"> Alper_Uzun@brown.edu </a>
      </p>
      <p>
        Nitin Sreekumar <br/>
        Brown University Class of 2025 <br/>
        <a href="mailto: nitin_sreekumar@brown.edu"> nitin_sreekumar@brown.edu </a>

      </p>
    </div>
  );
}

export default Contact;
