
import React from "react";
import "./pages.css"


function About() {
    return (
        <div className="about_body">
            <h1>About us</h1>
            <section className="about_section">
            <p>Varstack<sup>2</sup> is a tool which allows users to query multiple cancer gene databases at the same time and presents 
            information all in one place. It incorporates data from various databases, currently the Catalogue of Somatic Mutations in Cancer 
            (COSMIC), ClinVar, UCSC Genome Brower, cBioPortal, and Clinical Trials. Data is accessed using relevent APIs, meaning that the returned 
            information is up to date and current with the existing versions of the databases.
            </p> 
            </section>
            <h3>Database Information</h3>
            <ul>
                <li>
                <b>COSMIC:  </b> Provides Accession Number, Gene Length, Gene Name, HGNC ID, Mutation Description, Mutation Genome Position, Mutation ID, Legacy ID, Genomic Muation ID, Histology and Primary Site of the entered information.
                </li>
                <li>
                <b>ClinVar: </b> Links to the Cinvar site search for the given parameters.
                </li>
                <li>
                <b>cBioPortal: </b> Creates a graph which shows the number of appearences of the chosen peptide change in the chosen cancer.
                </li>
                <li>
                <b>UCSC Geome Browser: </b> Links to the UCSC genome browser for the chosen peptide change and codon modification. 
                </li>
                <li>
                <b>ClinicalTrials.gov: </b> Provides information regarding clinical trials using the given gene and peptide change (including Status, Study Title, Conditions, Interventions, and Locations.)
                </li>

            </ul>
        
            
            <h3>
                System Information
            </h3>
            <p>
                Varstack<sup>2</sup> is built using the React.js framework. COSMIC, cBioPortal, and Clinical Trials are accessed using the site's 
                Application Programming Interface (API), and the UCSC genome browser and ClinVar are viewed using an iframe.
            </p>
            
        </div>
    );
};
 
export default About;