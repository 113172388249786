import React from "react";
import { Nav, NavLink, NavMenu, Logo }
    from "./NavBarElements";
import logo from '../images/varstack2.png'
 
const Navbar = () => {
    return (
        <>
              

            <Nav>
            <Logo src={logo} alt="" />
                <NavMenu>
                    
                    <NavLink to="/varstack2" activeStyle>
                        Home
                    </NavLink>

                    <NavLink to="/about" activeStyle>
                        About
                    </NavLink>
                    <NavLink to="/references" activeStyle>
                        References
                    </NavLink>
                    <NavLink to="/contact" activeStyle>
                        Contact Us
                    </NavLink>
                    <NavLink to="/guide" activeStyle>
                        Guide                    
                    </NavLink>
                </NavMenu>
            </Nav>
        </>
    );
};
 
export default Navbar;