import React from "react";

function References() {
  return (
    <div className="about_body">
      <h1>References</h1>
      <h4><a href = 'https://cancer.sanger.ac.uk/cosmic'>COSMIC</a></h4>
      
      <div
        className="csl-bib-body"
        style={{ lineHeight: 2, marginLeft: "2em", textIndent: "-2em" }}
      >
        <div className="csl-entry">
          Tate, J. G., Bamford, S., Jubb, H. C., Sondka, Z., Beare, D. M.,
          Bindal, N., Boutselakis, H., Cole, C. G., Creatore, C., Dawson, E.,
          Fish, P., Harsha, B., Hathaway, C., Jupe, S. C., Kok, C. Y., Noble,
          K., Ponting, L., Ramshaw, C. C., Rye, C. E., … Forbes, S. A. (2019).
          COSMIC: The Catalogue Of Somatic Mutations In Cancer.{" "}
          <i>Nucleic Acids Research</i>, <i>47</i>
          (D1), D941–D947.{" "}
          <a href="https://doi.org/10.1093/nar/gky1015">
            https://doi.org/10.1093/nar/gky1015
          </a>
        </div>
        <span
          className="Z3988"
          title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1093%2Fnar%2Fgky1015&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=COSMIC%3A%20the%20Catalogue%20Of%20Somatic%20Mutations%20In%20Cancer&rft.jtitle=Nucleic%20Acids%20Research&rft.volume=47&rft.issue=D1&rft.aufirst=John%20G&rft.aulast=Tate&rft.au=John%20G%20Tate&rft.au=Sally%20Bamford&rft.au=Harry%20C%20Jubb&rft.au=Zbyslaw%20Sondka&rft.au=David%20M%20Beare&rft.au=Nidhi%20Bindal&rft.au=Harry%20Boutselakis&rft.au=Charlotte%20G%20Cole&rft.au=Celestino%20Creatore&rft.au=Elisabeth%20Dawson&rft.au=Peter%20Fish&rft.au=Bhavana%20Harsha&rft.au=Charlie%20Hathaway&rft.au=Steve%20C%20Jupe&rft.au=Chai%20Yin%20Kok&rft.au=Kate%20Noble&rft.au=Laura%20Ponting&rft.au=Christopher%20C%20Ramshaw&rft.au=Claire%20E%20Rye&rft.au=Helen%20E%20Speedy&rft.au=Ray%20Stefancsik&rft.au=Sam%20L%20Thompson&rft.au=Shicai%20Wang&rft.au=Sari%20Ward&rft.au=Peter%20J%20Campbell&rft.au=Simon%20A%20Forbes&rft.date=2019-01-08&rft.pages=D941-D947&rft.spage=D941&rft.epage=D947&rft.issn=0305-1048%2C%201362-4962&rft.language=en"
        />
      </div>
      <h4><a href = 'https://www.ncbi.nlm.nih.gov/clinvar/'>Clinvar</a></h4>
      <div
        className="csl-bib-body"
        style={{ lineHeight: 2, marginLeft: "2em", textIndent: "-2em" }}
      >
        <div className="csl-entry">
      Landrum, M. J., Lee, J. M., Benson, M., Brown, G. R., Chao, C.,
      Chitipiralla, S., Gu, B., Hart, J., Hoffman, D., Jang, W., Karapetyan,
      K., Katz, K., Liu, C., Maddipatla, Z., Malheiro, A., McDaniel, K.,
      Ovetsky, M., Riley, G., Zhou, G., … Maglott, D. R. (2018). ClinVar:
      Improving access to variant interpretations and supporting evidence.{" "}
      <i>Nucleic Acids Research</i>, <i>46</i>(D1), D1062–D1067.{" "}
      <a href="https://doi.org/10.1093/nar/gkx1153">
        https://doi.org/10.1093/nar/gkx1153
      </a>
    </div>
    <span
      className="Z3988"
      title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1093%2Fnar%2Fgkx1153&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=ClinVar%3A%20improving%20access%20to%20variant%20interpretations%20and%20supporting%20evidence&rft.jtitle=Nucleic%20Acids%20Research&rft.volume=46&rft.issue=D1&rft.aufirst=Melissa%20J&rft.aulast=Landrum&rft.au=Melissa%20J%20Landrum&rft.au=Jennifer%20M%20Lee&rft.au=Mark%20Benson&rft.au=Garth%20R%20Brown&rft.au=Chen%20Chao&rft.au=Shanmuga%20Chitipiralla&rft.au=Baoshan%20Gu&rft.au=Jennifer%20Hart&rft.au=Douglas%20Hoffman&rft.au=Wonhee%20Jang&rft.au=Karen%20Karapetyan&rft.au=Kenneth%20Katz&rft.au=Chunlei%20Liu&rft.au=Zenith%20Maddipatla&rft.au=Adriana%20Malheiro&rft.au=Kurt%20McDaniel&rft.au=Michael%20Ovetsky&rft.au=George%20Riley&rft.au=George%20Zhou&rft.au=J%C2%A0Bradley%20Holmes&rft.au=Brandi%20L%20Kattman&rft.au=Donna%20R%20Maglott&rft.date=2018-01-04&rft.pages=D1062-D1067&rft.spage=D1062&rft.epage=D1067&rft.issn=0305-1048%2C%201362-4962&rft.language=en"
    />
    </div>
      <h4><a href = 'https://www.cbioportal.org/'>cBioPortal</a></h4>      
      <div
        className="csl-bib-body"
        style={{ lineHeight: 2, marginLeft: "2em", textIndent: "-2em" }}
      >
        <div className="csl-entry">
          Cerami, E., Gao, J., Dogrusoz, U., Gross, B. E., Sumer, S. O., Aksoy,
          B. A., Jacobsen, A., Byrne, C. J., Heuer, M. L., Larsson, E., Antipin,
          Y., Reva, B., Goldberg, A. P., Sander, C., &amp; Schultz, N. (2012).
          The cBio Cancer Genomics Portal: An Open Platform for Exploring
          Multidimensional Cancer Genomics Data. <i>Cancer Discovery</i>,{" "}
          <i>2</i>(5), 401–404.{" "}
          <a href="https://doi.org/10.1158/2159-8290.CD-12-0095">
            https://doi.org/10.1158/2159-8290.CD-12-0095
          </a>
        </div>
        <span
          className="Z3988"
          title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1158%2F2159-8290.CD-12-0095&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=The%20cBio%20Cancer%20Genomics%20Portal%3A%20An%20Open%20Platform%20for%20Exploring%20Multidimensional%20Cancer%20Genomics%20Data&rft.jtitle=Cancer%20Discovery&rft.volume=2&rft.issue=5&rft.aufirst=Ethan&rft.aulast=Cerami&rft.au=Ethan%20Cerami&rft.au=Jianjiong%20Gao&rft.au=Ugur%20Dogrusoz&rft.au=Benjamin%20E.%20Gross&rft.au=Selcuk%20Onur%20Sumer&rft.au=B%C3%BClent%20Arman%20Aksoy&rft.au=Anders%20Jacobsen&rft.au=Caitlin%20J.%20Byrne&rft.au=Michael%20L.%20Heuer&rft.au=Erik%20Larsson&rft.au=Yevgeniy%20Antipin&rft.au=Boris%20Reva&rft.au=Arthur%20P.%20Goldberg&rft.au=Chris%20Sander&rft.au=Nikolaus%20Schultz&rft.date=2012-05-01&rft.pages=401-404&rft.spage=401&rft.epage=404&rft.issn=2159-8274%2C%202159-8290&rft.language=en"
        />
        <div className="csl-entry">
          Gao, J., Aksoy, B. A., Dogrusoz, U., Dresdner, G., Gross, B., Sumer,
          S. O., Sun, Y., Jacobsen, A., Sinha, R., Larsson, E., Cerami, E.,
          Sander, C., &amp; Schultz, N. (2013). Integrative Analysis of Complex
          Cancer Genomics and Clinical Profiles Using the cBioPortal.{" "}
          <i>Science Signaling</i>, <i>6</i>(269).{" "}
          <a href="https://doi.org/10.1126/scisignal.2004088">
            https://doi.org/10.1126/scisignal.2004088
          </a>
        </div>
        <span
          className="Z3988"
          title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1126%2Fscisignal.2004088&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=Integrative%20Analysis%20of%20Complex%20Cancer%20Genomics%20and%20Clinical%20Profiles%20Using%20the%20cBioPortal&rft.jtitle=Science%20Signaling&rft.stitle=Sci.%20Signal.&rft.volume=6&rft.issue=269&rft.aufirst=Jianjiong&rft.aulast=Gao&rft.au=Jianjiong%20Gao&rft.au=B%C3%BClent%20Arman%20Aksoy&rft.au=Ugur%20Dogrusoz&rft.au=Gideon%20Dresdner&rft.au=Benjamin%20Gross&rft.au=S.%20Onur%20Sumer&rft.au=Yichao%20Sun&rft.au=Anders%20Jacobsen&rft.au=Rileen%20Sinha&rft.au=Erik%20Larsson&rft.au=Ethan%20Cerami&rft.au=Chris%20Sander&rft.au=Nikolaus%20Schultz&rft.date=2013-04-02&rft.issn=1945-0877%2C%201937-9145&rft.language=en"
        />
      </div>
      <h4><a href = 'https://genome.ucsc.edu/cgi-bin/hgGateway'>UCSC Genome Browser</a></h4>    
      <div
        className="csl-bib-body"
        style={{ lineHeight: 2, marginLeft: "2em", textIndent: "-2em" }}
      >
        <div className="csl-entry">
          Kent, W. J., Sugnet, C. W., Furey, T. S., Roskin, K. M., Pringle, T.
          H., Zahler, A. M., &amp; Haussler, A. D. (2002). The Human Genome
          Browser at UCSC. <i>Genome Research</i>, <i>12</i>(6), 996–1006.{" "}
          <a href="https://doi.org/10.1101/gr.229102">
            https://doi.org/10.1101/gr.229102
          </a>
        </div>
        <span
          className="Z3988"
          title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1101%2Fgr.229102&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=The%20Human%20Genome%20Browser%20at%20UCSC&rft.jtitle=Genome%20Research&rft.stitle=Genome%20Res.&rft.volume=12&rft.issue=6&rft.aufirst=W.%20James&rft.aulast=Kent&rft.au=W.%20James%20Kent&rft.au=Charles%20W.%20Sugnet&rft.au=Terrence%20S.%20Furey&rft.au=Krishna%20M.%20Roskin&rft.au=Tom%20H.%20Pringle&rft.au=Alan%20M.%20Zahler&rft.au=And%20David%20Haussler&rft.date=2002-06-01&rft.pages=996-1006&rft.spage=996&rft.epage=1006&rft.issn=1088-9051%2C%201549-5469&rft.language=en"
        />
        <div className="csl-entry">
      Nassar, L. R., Barber, G. P., Benet-Pagès, A., Casper, J., Clawson,
      H., Diekhans, M., Fischer, C., Gonzalez, J. N., Hinrichs, A. S., Lee,
      B. T., Lee, C. M., Muthuraman, P., Nguy, B., Pereira, T., Nejad, P.,
      Perez, G., Raney, B. J., Schmelter, D., Speir, M. L., … Kent, W. J.
      (2023). The UCSC Genome Browser database: 2023 update.{" "}
      <i>Nucleic Acids Research</i>, <i>51</i>(D1), D1188–D1195.{" "}
      <a href="https://doi.org/10.1093/nar/gkac1072">
        https://doi.org/10.1093/nar/gkac1072
      </a>
    </div>
    <span
      className="Z3988"
      title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1093%2Fnar%2Fgkac1072&rft_id=info%3Apmid%2F36420891&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=The%20UCSC%20Genome%20Browser%20database%3A%202023%20update&rft.jtitle=Nucleic%20Acids%20Research&rft.stitle=Nucleic%20Acids%20Res&rft.volume=51&rft.issue=D1&rft.aufirst=Luis%20R.&rft.aulast=Nassar&rft.au=Luis%20R.%20Nassar&rft.au=Galt%20P.%20Barber&rft.au=Anna%20Benet-Pag%C3%A8s&rft.au=Jonathan%20Casper&rft.au=Hiram%20Clawson&rft.au=Mark%20Diekhans&rft.au=Clay%20Fischer&rft.au=Jairo%20Navarro%20Gonzalez&rft.au=Angie%20S.%20Hinrichs&rft.au=Brian%20T.%20Lee&rft.au=Christopher%20M.%20Lee&rft.au=Pranav%20Muthuraman&rft.au=Beagan%20Nguy&rft.au=Tiana%20Pereira&rft.au=Parisa%20Nejad&rft.au=Gerardo%20Perez&rft.au=Brian%20J.%20Raney&rft.au=Daniel%20Schmelter&rft.au=Matthew%20L.%20Speir&rft.au=Brittney%20D.%20Wick&rft.au=Ann%20S.%20Zweig&rft.au=David%20Haussler&rft.au=Robert%20M.%20Kuhn&rft.au=Maximilian%20Haeussler&rft.au=W.%20James%20Kent&rft.date=2023-01-06&rft.pages=D1188-D1195&rft.spage=D1188&rft.epage=D1195&rft.issn=1362-4962&rft.language=eng"
    />
    </div>
      <h4><a href = 'https://clinicaltrials.gov/'>ClinicalTrials.gov</a></h4>   
      <div
        className="csl-bib-body"
        style={{ lineHeight: 2, marginLeft: "2em", textIndent: "-2em" }}
      > 
      <div className="csl-entry">
        Zarin, D. A., Tse, T., Williams, R. J., Califf, R. M., &amp; Ide, N.
        C. (2011). The ClinicalTrials.gov Results Database—Update and Key
        Issues. <i>New England Journal of Medicine</i>, <i>364</i>(9),
        852–860.{" "}
        <a href="https://doi.org/10.1056/NEJMsa1012065">
          https://doi.org/10.1056/NEJMsa1012065
        </a>
      </div>
      <span
        className="Z3988"
        title="url_ver=Z39.88-2004&ctx_ver=Z39.88-2004&rfr_id=info%3Asid%2Fzotero.org%3A2&rft_id=info%3Adoi%2F10.1056%2FNEJMsa1012065&rft_val_fmt=info%3Aofi%2Ffmt%3Akev%3Amtx%3Ajournal&rft.genre=article&rft.atitle=The%20ClinicalTrials.gov%20Results%20Database%20%E2%80%94%20Update%20and%20Key%20Issues&rft.jtitle=New%20England%20Journal%20of%20Medicine&rft.stitle=N%20Engl%20J%20Med&rft.volume=364&rft.issue=9&rft.aufirst=Deborah%20A.&rft.aulast=Zarin&rft.au=Deborah%20A.%20Zarin&rft.au=Tony%20Tse&rft.au=Rebecca%20J.%20Williams&rft.au=Robert%20M.%20Califf&rft.au=Nicholas%20C.%20Ide&rft.date=2011-03-03&rft.pages=852-860&rft.spage=852&rft.epage=860&rft.issn=0028-4793%2C%201533-4406&rft.language=en"
      /></div>
    </div>
  );
}

export default References;
