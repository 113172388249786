import './App.css';
import Window from './components/Window/window';
import About from './components/pages/about';
import Header from './components/Header/Header';
import Navbar from './components/Header/NavBar';
import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
import References from './components/pages/references';
import Contact from './components/pages/contact';
import Guide from './components/pages/guide';


function App() {
  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header> */}
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Window />}/>
          <Route path='/about' element={<About/>}/>
          <Route  path='/references' element={<References />}/>
          <Route path='/contact' element={<Contact/>}/>
          <Route path='/guide' element={<Guide/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
