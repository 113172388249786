import React from "react";
import "./pages.css";

import input from "../images/Guide1.png" 
import cosmic from "../images/Guide2.png" 
import cbio from "../images/cbioPortalGuide.png" 
import UCCS from "../images/UCSCguide.png" 
import clinvar from "../images/ClinvarGuide.png" 
import trials from "../images/TrialsGuide.png" 
import smart from "../images/smartguide.png" 
import exp from "../images/exportGuide.png" 



function Guide() {
    return (
        <div className="about_body">
            <h1>
                Guide
            </h1>
            <img className="images" src={input} alt="" /> <br/>
            Input the HUGO gene ID of the gene of interest (eg KRAS, EGFR, BRCA), and if known, the relevent peptide change and codon change. Click the submit button.<br/> <br/>
            <img className="images" src={smart} alt="" /> <br/>
            If no peptide and/or codon change has been selected, the relevent dropdown menus will provide possible peptide and codon changes which have been linked to the gene in the COSMIC database. Selecting a peptide change or codon change from the dropdown menus will automatically update the query. <br/> <br/>
            After submitting the query, simply scroll down to view infromation from the desired database. <br/> <br/>
            <img className="images" src={exp} alt="" /> <br/>
            If the data needs to be downloaded, the Export All Databases button under the data entry section provides all the data in one excel file, while each daatabase section can also be exported separataly by clicking on the link below the database name.   <br/> <br/>
            <img className="images" src={cosmic} alt="" /> <br/>
            In the COSMIC screen, the user is provided a list of results from COSMIC pertaining to the search, with each result providing the Accession Number, Gene Length, Gene Name, HGNC ID, Peptide Change, Coding Sequence Change, Mutation Description, mutation Genome Position, Mutation Strand, Mutation ID, Legacy Mutation ID, Genomic Mutation ID, Histology, and Primary Site. <br/> <br/>
            <img className="images" src={clinvar} alt="" /> <br/>
            In the ClinVar screen, the user is provided the ClinVar site loaded to the relavent search page for thier query, which they can then use as normal, including finding Interpretations, Conditions, and other varient information.  <br/> <br/>
            <img className="images" src={cbio} alt="" /> <br/>
            In the cBioPortal screen, the user is provided a graph which detatils how many times the gene shows up in each of the studies stored in cBioPortal, which allows users to see how common the mutation is in the selected cancer.  <br/> <br/>
            <img className="images" src={UCCS} alt="" /> <br/>
            In the UCCS Genome Browser screen, the user is provided a shortcut to the genmone browser of the selected varient, which provides relavent information on the location of the gene and sequence alignments.  <br/> <br/>
            <img className="images" src={trials} alt="" /> <br/>
            In the ClinicalTrials.gov screen, the user is provided with a table which has an entry for each clincial trial relavent to the query, and provides the Status, Study Title, Condidtions, Interventions, and Locations for each trial.

        </div>
    );
};
 
export default Guide;